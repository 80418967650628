import "./App.scss";
import Redirector from "./components/Redirector";
function App() {
  return (
    <>
      <Redirector />
    </>
  );
}

export default App;

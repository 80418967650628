import "./Redirector.scss";
import "./Redirector-animations.scss";
import "./Redirector-RWD.scss";
import { Gear } from "react-bootstrap-icons";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
const Redirector = () => {
  const intervalFunction = () => {
    const gears = document.querySelectorAll(".gear");
    const counterElement = document.querySelector(".counter");
    const loader = document.querySelector(".loader");
    let counter = 3;
    counterElement.classList.add("counter-animation");

    const interval = setInterval(() => {
      if (counter >= 1 && counter - 1 !== 0) {
        counter -= 1;
        counterElement.innerText = counter;
        gears.forEach((gear) => {
          console.log(gear);
          if (gear.classList.contains(`gear-rotating${counter + 1}s-left`)) {
            gear.classList.remove(`gear-rotating${counter + 1}s-left`);
          }
          gear.classList.add(`gear-rotating${counter}s-left`);
        });
      } else {
        window.location = "https://solistraktor.pl";
        counterElement.classList.remove("visible");
        counterElement.classList.add("hiden1");
        counterElement.classList.remove("counter-animation");
        counterElement.style.display = "none";
        gears.forEach((gear) => {
          gear.classList.remove("gear-rotating1s-left");
          gear.classList.add("gear-rotating-last");
        });
        loader.classList.remove("hiden-loader");
        clearInterval(interval);
      }
    }, "1000");
  };
  const showLogoAnimation = () => {
    const hidenElements1 = document.querySelectorAll(".hiden1");
    const hidenElements2 = document.querySelectorAll(".hiden2");
    const sAgroLogo = document.querySelector(".s-agro-logo-container");
    const titleBack = document.querySelector(".title-back");
    const topGear = document.querySelector(".top-gear");
    sAgroLogo.style.transition = "0s";
    const sAgroCircle = sAgroLogo
      .querySelector(".s-agro-logo")
      .querySelector(".logo-circle-pt");
    sAgroLogo.classList.add("s-agro-logo-container-show");
    sAgroLogo.querySelector(".s-agro-logo").classList.add("s-agro-logo-show");
    sAgroCircle.classList.add("logo-circle-pt-show");
    setTimeout(() => {
      sAgroCircle.classList.remove("logo-circle-pt-show");
      setTimeout(() => {
        sAgroLogo.style.transition = "0.5s";
        sAgroLogo.classList.remove("s-agro-logo-container-show");
        sAgroLogo
          .querySelector(".s-agro-logo")
          .classList.remove("s-agro-logo-show");
        titleBack.classList.add("title-back-show");
        setTimeout(() => {
          hidenElements1.forEach((element) => {
            element.classList.remove("hiden1");
            element.classList.add("visible");
          });
        }, "500");
        setTimeout(() => {
          document.querySelector(".tractors").classList.remove("hiden");
          document.querySelector(".tractors").classList.add("visible");
          setTimeout(() => {
            titleBack.classList.remove("title-back-show");
            titleBack.classList.add("title-back-show-return");
            document
              .querySelector(".gradient-back")
              .classList.remove("transformed");

            topGear.classList.remove("transhiden");
            hidenElements2.forEach((element) => {
              element.classList.remove("hiden2");
              element.classList.add("visible");
            });
            setTimeout(() => {
              intervalFunction();
            }, "600");
          }, "300");
        }, "1000");
      }, "800");
    }, "900");
  };

  useEffect(() => {
    showLogoAnimation();
  });
  return (
    <div className="redirector-container">
      <div className="logos-container">
        <div className="s-agro-logo-container">
          <div className="s-agro-logo">
            <img
              alt="s-agro-logo"
              className="logo-back-pt"
              src="img/logo-back-pt.svg"
            />
            <img
              alt=""
              className="logo-circle-pt"
              src="img/logo-circle-pt.svg"
            />
            <img
              alt=""
              data-aos="zoom-in"
              className="logo-letter-pt"
              src="img/logo-letter-pt.svg"
              data-aos-duration="1000"
            />
          </div>
        </div>
        <img
          alt="solis-logo"
          className="solis-logo hiden1"
          src="img/solis-logo.png"
        />
      </div>
      <div className="text-content">
        <div className="title-back">
          <h1 className="title hiden1">Importer Solis Professional w Polsce</h1>
        </div>

        <div className="gears-description">
          <p className="description hiden2">
            Przekierowanie na Solis Profesional<br></br>po więcej szczegółów za
          </p>
          <div className="gears-container hiden2">
            <div className="top-gear-container gear-container">
              <Gear className="gear top-gear transhiden" />
            </div>
            <div className="bottom-gear-container gear-container">
              <Gear className="gear bottom-gear" />
            </div>
          </div>
        </div>
        <p className="counter hiden2">3</p>

        <div className="loader hiden-loader">
          <p>zbiór danych</p>
          <div className="words">
            <span className="word">gwarancja</span>
            <span className="word">silnik</span>
            <span className="word">waga</span>
            <span className="word">komfort</span>
            <span className="word">udźwig</span>
            <span className="word">gwarancja</span>
          </div>
        </div>
      </div>
      <div className="photos-container">
        <div className="tractors hiden">
          <div className="left-tractor tractor">
            <img alt="" src="img/left-tractor.png" />
            <img alt="" className="blured-version" src="img/left-tractor.png" />
          </div>
          <div className="right-tractor tractor">
            <img alt="" src="img/right-tractor.png" />
            <img
              alt=""
              className="blured-version"
              src="img/right-tractor.png"
            />
          </div>
        </div>
        <div className="gradient-back transformed"></div>
      </div>
    </div>
  );
};
export default Redirector;
